
@font-face{
  font-family: "Roboto";
  src: url("https://cdnjs.cloudflare.com/ajax/libs/materialize/0.97.8/fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

body{
  font-family: "Roboto";
}

.k-pdf-export *{
  font-family: "Roboto";
  font-weight: 500;
  font-size: 10px;
}

.k-pdf-export .powered-by{
  margin-right: 10px;
}

.k-pdf-export .specialRecommendation{
  width: 100%;
}

.k-pdf-export .spectial-rec-container{
  width: 100%;
}

.canvas-bg{
  position: relative;
}

.ball{
  background-color: blue;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

div.main-panel{
  background-color: white;
}

.patient-record-sub{
  border-top: none;
  padding: 0px 10px;
}

.patient-record-card{
  border-radius: 0px;
  margin-top: 40px;
}

.case-id-form{
  border-radius: 10px;
  padding: 20px;
}

.patient-record-heading{
  margin-bottom: 0px;
  margin-top: 0px;
}

.patient-record-card{
  border-bottom: 1px solid rgb(0 0 0 / 13%);
}

.record-icon-container{
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #bdbdbd;
  margin-right: 10px;
}

.record-icon{
  position: absolute;
  top: 11px;
  left: 13px;
}

.record-add{
  padding-top: 13px;
  font-size: 18px;
  color: blue;
  cursor: pointer;
}

.record-add-icon{
  vertical-align: -7px;
  margin-right: 5px;
}

.card.card-home{
  background-color: #f4f6f8;
}

.upload-form{
  margin: 20px;
}

.submit-button{
  background-color: #bdbdbd;
  color: #484848;
  border: 1px solid gray;
  font-weight: bold;
  padding: 10px 15px;
}

.error-message{
  color: red;
  padding: 10px 15px;
  font-size: 18px;
  margin-bottom: 0px;
}

.case-images-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5em;
  padding: 20px;
}

.case-agreement{
  padding: 20px;
}

.case-agreement input[type=checkbox]{
  transform: scale(1.1);
  padding: 10px;
}

.images-inner{
  display: flex;
  justify-content: center;
  width: 100%;
  height: 300px;
  border: 1px solid black;
  position: relative;  
}

.case-images-container .images-inner{
  height: 400px;
}

.images-inner > img{
  width: 100%;
  height: 100%;
}

.spinner{
  position: absolute;
  left: 48%;
  top: 48%;
}

.admin-card{
  min-height: 95vh;
}

.hover-image{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff85;
  font-size: 18px;
  z-index: 50;
}

.form-container{
  position: relative;
}

.main-container{
  max-width: 1000px;
  margin: 0px auto 20px auto;
}

.spinner-container{
  position: absolute;
  width: 100%;
  height: 105%;
  display: flex;
  justify-content: center;
  background-color: #80808075;
}

.accordion-button:not(.collapsed) {
  color: black !important;
  background-color: white !important; 
}

.accordion-button:focus{
  box-shadow: none !important;
  border-bottom: 1px solid #dee2e6;
}

.accordion-diagnosis{
  width: 100%;
  background-color: #6eb2fe;
  color: white;
  padding: 10px 15px;
}

.accordion{
  font-weight: bold;
  font-size: 15px;
  padding: 10px;
}

.accordion p{
  margin: 0px;
}

.treatment-plan-footer{
  display: flex;
  flex-direction: row;
  border: none;
  margin: 10px 0px;
  border-radius: 0px;
  justify-content: space-between;
  padding: 10px 0px;
}

.treatment-plan-footer button{
  border: none;
  padding: 5px 7px;
}

.nav-brand{
  cursor: pointer;
}

.file_name{
  font-size: 18px;
  padding: 10px 15px;
  max-width: 250px;
  text-align: center;
  margin: 0px auto;
  overflow-x: hidden;
}

.modified{
  width: 100%; 
  height: 100%;
  color: green;
  position: absolute;
  top: 0;
  z-index: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: bold;
}

.modified > p{
  display: inline-block;
  padding: 5px 10px;
  border: 2px solid green;  
  transform: rotate(-45deg);
}

.confirmation-container{
  position: fixed;
  top: 0px;
  padding: 10% 20%;
  width: 100%;
  height: 100%;
  background-color: #ffffff52;
  z-index: 100;
}

.acc-class{
  border: 1px solid rgb(0 0 0 / 18%);
  border-left: none;
  border-right: none;
  box-shadow: none;
  border-radius: 0px;
  margin-top: -1px;
  margin-bottom: 0px;
  padding: 0px 0px 10px;
}

.acc-class span{
  font-weight: 100;
}

.acc-class.tp{
  margin-top: -1px;
  padding-bottom: 15px;
}

.patient-images-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.5em;
}

.brand-logo{
  display: inline-block;
  font-size: 30px;
  padding: 13px 5px;  
  background-color: white;
  border-radius: 3px;
}

.rabie{
  background-color: #5858ff;
  color: white;
  padding: 5px 7px;
  margin: 5px 5px 5px -2px;
}

.ai{
  color: #5858ff;
  font-weight: 800; 
}

.react-date-picker{
  width: 100%;
  padding: 3px;
}

.datepicker-container{
  border-radius: 5px;
  border: 1px solid lightgray;
}

.react-date-picker__wrapper{
  border: none;
  border-radius: 5px;
}

#imgBg img{
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.upload-container{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

}

.select{
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.brand-logo-small{
  display: inline-block;
  padding: 5px 5px;  
  background-color: white;
  border-radius: 3px;
  margin-left: 5px;
}

.rabie-small{
  background-color: #5858ff;
  color: white;
  padding: 5px 7px;
  margin: 5px 5px 5px -2px;
}

.ai-small{
  color: #5858ff;
  font-weight: bold; 
}

.specialRecommendation{
  white-space: pre-wrap;  
  font-weight: 400;
}

.dropzone-inner{
  border: 1px solid black;
}



.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.login-container{
  max-width: 600px;
  margin: 50px auto;
}

.login-spinner-container{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 50px auto;
  height: 500px;
  border: 1px solid lightgray;
  border-radius: 1em;
}

.login-form{
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  border-radius: 1em;
  padding: 1.5em;
}

.login-form h3, .login-form .brand-logo{
  align-self: center;
}

.patient-details > div{
  display: flex;
}

.patient-details{
  width: 90%;
  margin: 10px auto;
}

.recommendationAlert{
  border-radius: 3px;
  padding: 8px;
}

.copy{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 30px 0px 5px 0px;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}

.token-warning{
  margin-bottom: 30px !important;
}

.copy > p{
  margin: 0px 5px;
}

.copy-icon-container{
  background-color: #80808047;
  padding: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.token-input-container{
  position: relative;
}

.token-input-loader{
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #80808047;
}

.disclaimer{
  margin: 30px 0px 20px;
}

.disclaimer p{
  margin: 0px;
  margin-bottom: 5px;
  font-weight: bold;
}

.disclaimer small{
  font-weight: 200;
  line-height: .8;
}
